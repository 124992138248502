import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';

import {initEnvironmentVariables} from './utils/env';

if (process.env.NODE_ENV === 'development') {
	loadDevMessages();
}
loadErrorMessages();

void (async () => {
	try {
		await initEnvironmentVariables();
	} catch (err) {
		console.error(err);
		await mountErrorPage('An error occurred while communicating with the server.');
		return;
	}

	try {
		await import('./authorization').then(({signin}) => signin());
	} catch (err) {
		console.error(err);
		await mountErrorPage('Failed to contact the sign-in service.');
		return;
	}

	try {
		await import('./initApplicationState').then(({default: initApplicationState}) => initApplicationState());
	} catch (err) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		const statusCode = typeof err === 'object' ? (err as any).networkError?.statusCode : undefined;
		if (statusCode && typeof statusCode === 'number' && statusCode === 403) {
			await mountErrorPage('Insufficient permissions: please contact your administrator');
		} else {
			console.error(err);
			await mountErrorPage('An error occurred while communicating with the server.');
		}
		return;
	}

	try {
		await import('./mountApp').then(({default: mountApp}) => mountApp());
	} catch (err) {
		console.error(err);
		await mountErrorPage('An error occurred while loading the application.');
		return;
	}
})();

async function mountErrorPage(message: string) {
	await import('./mountErrorPage').then(({default: mountErrorPage}) => mountErrorPage(message));
}
